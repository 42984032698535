<template>
    <div class="col-12 g">
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th>
                            المستخدم
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            السبب
                        </th>
                        <th>
                            اتصل على
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="user in calls" :key="user._id" :class="user.status == 0 ? 'bg-light-success' : ''">
                            <td>
                                <span v-if="user.user">
                                    <span v-if="user.user._id">
                                        <a target="_blank" :href="'https://s.tahdir.net/autologin/' + user.user.jwt">
                                        <i class="fa fa-external-link"></i>
                                        {{user.user.name}} </a>
                                    </span>
                                </span>
                            </td>
                            <td>
                                {{ user.date }}
                            </td>
                            <td>
                                {{ user.reason }}
                            </td>
                            <td>
                                {{ user.phone }}
                            </td>
                            <td>
                                <span v-if="user.status == 0" class="btn btn-sm btn-warning">في الانتظار</span>
                                <span v-if="user.status == 1" class="btn btn-sm btn-success">تم الاتصال</span>
                                <span v-if="user.status == 2" class="btn btn-sm btn-danger">ملغى</span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-success" style="border-radius: 0px" v-if="user.status == 0" @click="done(user._id)">
                                    <i class="fa fa-check"></i>
                                    تم الاتصال
                                </button>
                                <button class="btn btn-sm btn-danger" style="border-radius: 0px" v-if="user.status == 0" @click="cancel(user._id)">
                                    <i class="fa fa-times"></i>
                                    الغاء
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            loading: true,
            loading2: true,
            jwt: localStorage.getItem('jwt'),
            calls: [],
        }
    },
    created(){
        var g = this;
        g.gett();
    },
    methods: {
        gett(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/calls', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.calls = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        done(id){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/calls/done', {
                jwt: this.jwt,
                id: id
            }).then(function(r){
                g.gett()
            }).fail(function(){
                g.loading = false;
            })
        },
        cancel(id){
            var g = this;
            g.loading = true;
            var t = prompt("السبب", "");
            if(t && t != ""){
                $.post(api + '/admin/calls/cancel', {
                jwt: this.jwt,
                id: id,
                reason: t
            }).then(function(r){
                g.gett()
            }).fail(function(){
                g.loading = false;
            })
            }
        },
    }
}
</script>
<style scoped>
</style>